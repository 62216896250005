<script setup>
import Arrow from 'assets/images/arrow.svg'

defineProps({
	image: {
		type: Object,
		default: null
	},
	title: {
		type: String,
		required: true
	},
	link: {
		type: String,
		required: true
	},
	eventName: {
		type: String,
		default: null
	},
	index: {
		type: Number,
		default: null
	}
})

const isExternalLink = (link) => link?.startsWith('http')

const { trackEvent } = useFathom()
</script>

<template>
	<NuxtLink
		v-editable
		:class="{
			'shop': link.includes('shop'),
			'is-even': (index + 1) % 2 === 0,
			'is-odd': (index + 1) % 2 !== 0
		}"
		class="page-tile gradient-overlay"
		:to="link"
		:target="isExternalLink(link) ? '_blank' : null"
		@click="trackEvent(eventName)"
	>
		<ImageElement
			v-if="image?.filename"
			:key="image.id"
			:src="image.filename"
			:alt="image.alt"
			:blurhash="image.source"
			:focal-point="image.focus"
			:preload="false"
			class="image"
		/>
		<div class="title-arrow">
			<span
				class="title"
				v-html="title"
			/>
			<Arrow class="arrow" />
		</div>
	</NuxtLink>
</template>

<style scoped>
.page-tile {
	--tile-padding: 1rem;
	--gradient-start: 55%;
	--gradient-opacity-end: 85%;

	position: relative;

	display: grid;
	align-items: end;

	aspect-ratio: 1 / 1;
	min-width: 0;

	color: var(--color-white);

	&::after {
		pointer-events: none;
		content: '';

		position: absolute;
		z-index: 1;
		inset: 0;

		opacity: var(--overlay-opacity, 0);
		background: var(--color-blue);
		mix-blend-mode: multiply;

		transition-duration: var(--transition-duration);
		transition-property: opacity;
	}

	&.shop.is-odd {
		display: none;

		@media (--large) {
			display: grid;
		}
	}

	.image {
		--image-position: absolute;

		position: absolute;
		z-index: 0;
		inset: 0;

		width: 100%;
		height: 100%;
	}

	&:hover,
	&:focus {
		--arrow-translate-x: 10%;
		--overlay-opacity: 0.6;
	}

	&:active {
		--overlay-opacity: 0.8;
	}

	@media (--extra-small) {
		--tile-padding: 2rem;
		--gradient-opacity-end: 60%;
	}

	@media (--medium) {
		--tile-padding: 2.25rem;
	}

	@media (--extra-large) {
		--tile-padding: var(--gap);
	}
}

.title-arrow {
	z-index: 2;

	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto 2rem;
	gap: 1rem;
	align-items: end;
	justify-content: space-between;

	padding: var(--tile-padding);

	@media (--extra-small) {
		grid-template-columns: auto 6rem;
		grid-template-rows: auto;
	}
}

.title {
	flex: 0 1 auto;

	font-size: var(--font-size-large);
	font-weight: var(--font-weight-bold);
	line-height: 1;
	hyphens: auto;
}

.arrow {
	transform: translateX(var(--arrow-translate-x, 0));
	width: 6rem;
	height: 2rem;
	transition-duration: var(--transition-duration);
}
</style>
